@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  cursor: pointer;
  display: inline;
}

p{
  margin-bottom: 0;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.modal-content{
  border: none;
  background: none;
}
.modal-dialog{
  border: none;
  background: none;
}

body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #242526; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

.MuiDialogContent-root::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.MuiDialogContent-root::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

.MuiPaper-root::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.MuiPaper-root::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

.MuiPaper-root::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}
.MuiGrid-root::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.MuiGrid-root::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.MuiGrid-root::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

.MuiGrid-root::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

.k-grid-content::-webkit-scrollbar {
  width: 18px; /* width of the entire scrollbar */
}

.k-grid-content::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.k-grid-content::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

.k-grid-content::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

.MuiList-root::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.MuiList-root::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.MuiList-root::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

.MuiList-root::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}

.MuiDataGrid-window::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.MuiDataGrid-window::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 20px;
}

.MuiDataGrid-window::-webkit-scrollbar-thumb:hover {
  background-color: #00bcff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none; /* creates padding around scroll thumb */
}
