$primary: rgb(21, 25, 56);
$secondary: rgb(252, 204, 10);
$font-family: 'Lato';
$grid-bg: rgba(0, 0, 0, 0);
$grid-header-bg: rgba(0, 0, 0, 0);
$grid-footer-bg: rgba(0, 0, 0, 0);
$grid-alt-bg: rgba(0, 0, 0, 0);
$pager-bg: rgba(0, 0, 0, 0);
$tabstrip-content-bg: rgba(0, 0, 0, 0);
$listview-bg: rgba(0, 0, 0, 0);
$listview-border: rgba(0, 0, 0, 0);
$input-focused-border: rgb(120, 129, 198);
$input-border-width: 2px;
$textarea-default-width: 100%;
$textarea-focused-border: rgb(120, 129, 198);
$textarea-border-width: 2px;
$combobox-focused-border: rgb(120, 129, 198);

@import '~@progress/kendo-theme-default/dist/all.scss';

//KendoButtons

.k-button {
  border: none;
  color: $primary;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  background-color: rgb(252, 204, 10);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.k-button-padding {
  padding: 6px 16px;
}

.k-button:active {
  background-color: rgb(252, 204, 10);
  color: white;
  box-shadow: none;
  font-size: 0.875rem;
}

.k-button:hover {
  background-color: rgb(252, 204, 10);
  box-shadow: none;
}

.k-button.k-flat {
  padding: 0;
  color: rgb(120, 129, 198);
}

.k-button.k-flat:hover {
  color: $secondary;
}


.k-button.k-flat:focus {
  color: $secondary;
}

.k-button.k-outline {
  border: 3px solid $secondary;
}

.k-button.k-outline:hover {
  background-color: rgb(234, 239, 250);
  color: $primary;
}

.k-button.k-outline.k-primary {
  border: 3px solid $primary;
}

.k-button.k-outline.k-primary:hover {
  border: 3px solid $secondary;
  background-color: rgb(255, 254, 242);
  color: $primary;
}

//-----------

.k-label {
  font-size: 14px;
}

.k-combobox {
  height: 32px;
}

.k-combobox .k-dropdown-wrap {
  border-width: 2px !important;
}

.k-color-secondary {
  background-color: transparent !important;
}

.k-grid-header {
  padding-right: 10px;
  padding-inline-end: 10px !important;
}

.k-state-active {
  color: rgb(21, 25, 56) !important;
}

.k-tabstrip-items {
  justify-content: center;
}

.k-chart-tooltip-wrapper {
  width: auto !important;
}

.k-animation-container {
  z-index: 3000 !important;
}

.k-toolbar {
  background-color: transparent !important;
}

select {
  z-index: 5000;
}

option {
  z-index: 5000;
}

.k-dialog {
  border-radius: 4px;
}

.k-dialog-buttongroup {
  padding: 15px 25px;
}

.k-dialog-content {
  padding: 25px;
}
.nav-global-search{
  position:relative;
  pointer-events: auto;
 
}
.fa-lock{
  position:absolute;
  right: 4px;
  bottom: 21px;
  color:#91BE55;
  //color:#d61c60;
}
.global-unlock{
  position:absolute;
  right: 4px;
  bottom: 21px;
  color:#91BE55;
}
.fa-angle-double-right{
  color:#91BE55;
  margin-right: 0.5rem;
}
.payment-loader {
  border: 8px solid #91BE55;
  border-radius: 50%;
  border-top: 8px solid #f3f3f3;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.fa-check{
  color:#91BE55;
}
.fa-times-circle{
  color:#ff0033;
}
.global-search-hover{
  padding:2px 3px;
  position:absolute;
  right: 26px;
  bottom: 50px;
  color:#91BE55;
  // background-color: #91BE55;
  letter-spacing: 1px;
  border-radius: 3px;
  display: none;
  
}
.global-search-incomplete{
  position:absolute;
  right: 17px;
  bottom: 55px;
  color:#91BE55 !important;
  // background-color: #91BE55;
  letter-spacing: 0px;
 
  text-transform: none;
  font-size: 0.85rem;
  display: none;

}
.nav-global-search:hover{
   cursor: auto;
  .global-search-hover{
  display:block;
}
.global-search-incomplete{
  display:block;
}




}
.nav-global-search:active{
 pointer-events: none;
  .global-search-hover{
    display:block;}
}
.premium-flag{
position: relative;
}
.payment-warning{
  position:absolute;
  right:0%;
  bottom:-40px;
  text-transform: none;
}
.payment-warning-off{
  display: none;
 
}

.subscription-flag-inactive{
  display: none;
}
